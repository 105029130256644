import React from "react"
import Write from "../images/icons/write.png"
import Report from "../images/icons/report.png"
import Branding from "../images/icons/branding.png"
import Workspace from "../images/icons/workspace.png"
import Store from "../images/icons/store.png"
import Licensing from "../images/icons/licensing.png"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "./index.styl"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />

    <h1>Have a Lasting Impact</h1>
    <p><em>Improve outcomes before, between, and beyond coaching and training sessions.</em></p>

    <h2>The Continuous Coaching Platform</h2>
    <p>
      As a coach you work hard to develop your materials and stay engaged with your clients. You should use a platform
      that works just as hard as you do.
    </p>

    <h2>How does it help?</h2>

    <section className="features">
      <article>
        <img height="50" src={Write} />
        <h3>Write Your Investigation</h3>
        <p>
          Investigations are how you find out what's going on with your coachees. Go beyond passive surveys and put your
          own "coaching intelligence" in to enable custom reports that provide depth to your clients, automatically.
        </p>
      </article>

      <article>
        <img height="50" src={Report} />
        <h3>Beautiful Printed Reports</h3>
        <p>
          When your coachees participate in investigations, you can provide them automatically with printable reports of
          their answers, along with your investigation commentary on their individual responses.
        </p>
      </article>

      <article>
        <img height="50" src={Branding} />
        <h3>Custom Branding</h3>
        <p>
          Your investigations, reports, and your store can all be adorned with your own logo and colour scheme. Keep
          your
          brand consistent with your other materials, and say goodbye to tedious formatting and layout.
        </p>
      </article>

      <article>
        <img height="50" src={Workspace} />
        <h3>Workspaces Provide Focus</h3>
        <p>
          Gather your coachees into workspaces in order to focus your work with them. Easily send an investigation with
          a
          few clicks into any of your active workspaces. Organize by topic, level of experience, whatever makes sense
          for
          you.
        </p>
      </article>

      <article>
        <img height="50" src={Store} />
        <h3>Optional Storefront</h3>
        <p>
          We're like an app store for your Investigations. We take care of all the commerce, you set your prices, and we
          send you your commissions. Take your years of experience, put it into a custom branded storefront just for
          you.
        </p>
      </article>

      <article>
        <img height="50" src={Licensing} />
        <h3>Simple Licensing</h3>
        <p>
          Use it for your private coaching practice with a low monthly price per user, or talk to us about enterprise
          pricing for our on-premise edition, designed for organizations wishing to build multi-level internal coaching
          programs.
        </p>
      </article>
    </section>

    <p>
      Our tools and technologies help coaches engage their clients or teams, measure improvements, and change
      behaviours. Unlike tools that reduce a coaches workload by reducing the team to an average, our software can make
      coaching more efficient, effective, and personal!
    </p>
    <p>
      ContinuousCoaching will help when:
    </p>

    <ul>
      <li>You have too many people to manage (span of responsibility)</li>
      <li>You don’t know what to tell them</li>
      <li>You are wasting your time giving formulaic feedback</li>
      <li>You don’t have the time to give personalised feedback</li>
      <li>Your team isn’t improving, or you don’t know if they are</li>
      <li>You are having trouble getting your team to a common understanding</li>
      <li>You don’t know what they’re experiencing, you feel disconnected from what’s happening in the field</li>
      <li>Behaviour reverts quickly with classic teaching/training techniques</li>
    </ul>
  </Layout>
)

export default IndexPage
